import React from "react";
import Slider from "react-slick";

import ProductCard from "../Card/ProductCard";
import "./ProductCarousel.scss";

export default class ProductCarousel extends React.Component {
  render() {
    const { products } = this.props;

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1408,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <Slider {...settings}>
        {products &&
          products.map(product => (
            <ProductCard
              key={product.id}
              imageData={product.image.gatsbyImageData}
              brand={product.brand.title}
              description={product.description}
              originalPrice={product.originalPrice}
              currency={
                product.currency && product.currency.symbol
                  ? product.currency.symbol
                  : null
              }
              price={product.price}
              slug={product.slug}
            />
          ))}
      </Slider>
    );
  }
}

import React, { Component } from "react";
import {
  FacebookShareButton,
  PinterestShareButton,
  RedditShareButton,
  TwitterShareButton,
  FacebookIcon,
  PinterestIcon,
  RedditIcon,
  TwitterIcon
} from "react-share";
import urljoin from "url-join";
import config from "../../../data/SiteConfig";
import "./SocialShares.scss";

export default class SocialShares extends Component {
  render() {
    const { postPath, title, description, pinterestMedia } = this.props;
    const url = urljoin(config.siteUrl, postPath);
    const iconSize = 40;

    return (
      <aside className="social-links">
        <FacebookShareButton url={url} quote={description}>
          <FacebookIcon size={iconSize} />
        </FacebookShareButton>
        <PinterestShareButton
          url={url}
          media={pinterestMedia}
          description={description}
        >
          <PinterestIcon size={iconSize} />
        </PinterestShareButton>
        <RedditShareButton url={url} title={title}>
          <RedditIcon size={iconSize} />
        </RedditShareButton>
        <TwitterShareButton url={url} title={title}>
          <TwitterIcon size={iconSize} />
        </TwitterShareButton>
      </aside>
    );
  }
}

import React, { Component } from "react";
import Helmet from "react-helmet";
import urljoin from "url-join";
import config from "../../../data/SiteConfig";

export default class SEO extends Component {
  render() {
    const { pageType, postNode, postPath } = this.props;
    let title;
    let description;
    let image;
    let postURL;

    if (pageType === "post") {
      // const postMeta = postNode.frontmatter;
      ({ title, description } = postNode);
      image = `https:${postNode.cover.file.url}`;
      postURL = urljoin(config.siteUrl, postPath);
    } else {
      title = config.siteTitle;
      description = config.siteDescription;
      image = urljoin(config.siteUrl, config.siteLogo);
    }

    const blogURL = config.siteUrl;
    const schemaOrgJSONLD = [
      {
        "@context": "http://schema.org",
        "@type": "WebSite",
        url: blogURL,
        name: config.siteTitle,
        alternateName: config.siteTitleAlt ? config.siteTitleAlt : ""
      }
    ];
    if (pageType === "post") {
      schemaOrgJSONLD.push({
        "@context": "http://schema.org",
        "@type": "BlogPosting",
        headline: title,
        image: {
          "@type": "ImageObject",
          url: image
        },
        datePublished: postNode.createdAt,
        dateModified: postNode.updatedAt,
        author: {
          "@type": "Person",
          name: config.authorName
        },
        publisher: {
          "@type": "Organization",
          name: config.siteTitle,
          url: blogURL,
          sameAs: [
            urljoin("https://www.twitter.com/", config.siteTwitterID),
            urljoin("https://www.facebook.com/", config.siteFBID),
            urljoin("https://www.pinterest.com/", config.sitePinterestID),
            urljoin("https://www.instagram.com/", config.siteInstagramID)
          ],
          logo: {
            "@type": "ImageObject",
            url: urljoin(config.siteUrl, config.siteLogoAMP),
            width: 250,
            height: 60
          }
        },
        mainEntityOfPage: postURL,
        description
      });
    }

    return (
      <>
        <Helmet>
          {/* General tags */}
          <meta name="description" content={description} />
          <meta name="image" content={image} />

          {/* Schema.org tags */}
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgJSONLD)}
          </script>

          {/* OpenGraph tags */}
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={pageType === "post" ? postURL : blogURL}
          />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={image} />
          <meta property="og:site_name" content={config.siteTitle} />
          <meta property="og:locale" content="en_US" />

          {/* Facebook tags */}
          <meta
            property="fb:admins"
            content={config.siteFBAdminID ? config.siteFBAdminID : ""}
          />
          <meta
            property="fb:page_id"
            content={config.siteFBPageID ? config.siteFBPageID : ""}
          />

          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:creator"
            content={config.siteTwitterID ? `@${config.siteTwitterID}` : ""}
          />
          <meta
            name="twitter:site"
            content={config.siteTwitterID ? `@${config.siteTwitterID}` : ""}
          />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={image} />
        </Helmet>

        {/* Article tags */}
        {pageType === "post" ? (
          <Helmet>
            {/* Overwrite og:type website */}
            <meta property="og:type" content="article" />
            <meta
              property="article:published_time"
              content={postNode.createdAt}
            />
            <meta
              property="article:modified_time"
              content={postNode.updatedAt}
            />
            <meta property="article:author" content={config.authorName} />
            <meta
              property="article:section"
              content={postNode.category.title}
            />
            {postNode.tags.map(tag => (
              <meta
                property="article:tag"
                content={tag.title}
                key={tag.title}
              />
            ))}
          </Helmet>
        ) : null}
      </>
    );
  }
}

import React, { Component } from "react";
import { Link } from "gatsby";

export default class PostTags extends Component {
  render() {
    const { tags } = this.props;
    return (
      <div className="tags">
        {tags &&
          tags.map(tag => (
            <Link
              key={tag.id}
              className="tag is-capitalized"
              to={`/tag/${tag.slug}`}
            >
              {tag.title}
            </Link>
          ))}
      </div>
    );
  }
}

import React from "react";
import "./SaleBanner.scss";

export default class SaleBanner extends React.Component {
  render() {
    return (
      <div>
        <a
          href="/go/sale/end"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <img
            className="square"
            width="auto"
            alt="END"
            src="https://images.ctfassets.net/70spxmhq4pdg/HoKZWHEwyieGUmkkyyW0M/71ccd73f77d964ffb434584ce29fe3d0/End_Sale.jpg"
          />
        </a>
        <a
          href="/go/sale/matchesfashion"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <img
            className="square"
            width="auto"
            alt="MATCHESFASHION"
            src="https://images.ctfassets.net/70spxmhq4pdg/3SICxehjQanRzCCxoGTEyP/5694d57f844ed3b1d5002104bfc6b049/MATCHESFASHION_Sale.gif"
          />
        </a>
        <a
          href="/go/sale/ssense"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <img
            className="square"
            width="auto"
            alt="SSENSE"
            src="https://images.ctfassets.net/70spxmhq4pdg/3eZcnmSS2Tq2vA3J7rKR8R/5571b8edac4c1597204992734522fdc6/SSENSE_Sale.gif"
          />
        </a>
        <a
          href="/go/sale/asos"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <img
            className="square"
            width="auto"
            alt="ASOS"
            src="https://images.ctfassets.net/70spxmhq4pdg/1CJWk3e4KGmoMF4GCMy0iF/64170d7dc284534ed3fb3d281c7e86fc/ASOS_Sale.gif"
          />
        </a>
        <a
          href="/go/sale/needsupply"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <img
            className="square"
            width="auto"
            alt="ASOS"
            src="https://images.ctfassets.net/70spxmhq4pdg/1sDDfePTcVTlxcYE3BdVp8/a9c30f3b4878f4867f6a2d93259dc8f9/Need_Supply_Sale.jpg"
          />
        </a>
      </div>
    );
  }
}

import React from "react";
import { GatsbyImage } from "gatsby-plugin-image"


export default class ProductCard extends React.Component {
  render() {
    const {
      imageData,
      brand,
      description,
      originalPrice,
      currency,
      price,
      slug
    } = this.props;

    return (
      <div className="card">
        <div className="card-image">
          <figure>
            <GatsbyImage
              image={imageData}
              alt={`${brand} ${description}`}
              title={`${brand} ${description}`}
            />
          </figure>
        </div>
        <div className="card-content">
          <div className="content">
            <p className="title is-spaced">{description}</p>
            <div className="is-flex">
              <p className="subtitle is-size-7">
                {originalPrice ? (
                  <>
                    {brand},{" "}
                    <span style={{ textDecoration: "line-through" }}>
                      {currency}
                      {originalPrice}
                    </span>{" "}
                    {currency}
                    {price}
                  </>
                ) : (
                  `${brand}, ${currency}${price}`
                )}
              </p>
              <a
                className="button is-primary is-small"
                href={slug}
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                Buy
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import moment from "moment";
import Layout from "../layout";
import PostTags from "../components/PostTags/PostTags";
import SocialShares from "../components/SocialShares/SocialShares";
import SEO from "../components/SEO/SEO";
import Disqus from "../components/Disqus/Disqus";
import config from "../../data/SiteConfig";
import ProductCarousel from "../components/ProductCarousel/ProductCarousel";
import SaleBanner from "../components/SaleBanner/SaleBanner";

export default class PostTemplate extends React.Component {
  // Lazy loading
  // https://developers.google.com/web/fundamentals/performance/lazy-loading-guidance/images-and-video/
  // https://github.com/gatsbyjs/gatsby/issues/2288#issuecomment-407836852
  // https://github.com/thomasboyt/loud-places/blob/7bca5da45f2b5c3ea676a2681be3a6809d47a53b/src/templates/blog-post.js#L9

  componentDidMount() {
    if (typeof IntersectionObserver === "undefined") {
      return;
    }

    const el = this.markdownContainer;

    const images = el.querySelectorAll("img");

    /* eslint no-param-reassign: "off" */
    images.forEach(image => {
      image.dataset.src = image.src;
      image.dataset.srcset = image.srcset;
      image.removeAttribute("src");
      image.removeAttribute("srcset");
      image.style.visibility = "hidden";
    });

    this.io = new window.IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          // Edge doesn't currently support isIntersecting, so also test for an intersectionRatio > 0
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const image = entry.target;
            image.src = image.dataset.src;
            image.srcset = image.dataset.srcset;
            image.style.visibility = "visible";
            this.io.unobserve(image);
          }
        });
      },
      { rootMargin: "200px" }
    );

    images.forEach(image => {
      this.io.observe(image);
    });
  }

  componentWillUnmount() {
    if (this.io) {
      this.io.disconnect();
    }
  }

  render() {
    const {
      pageContext: { slug, previous, next },
      data: { contentfulPost: post },
      location
    } = this.props;

    return (
      <Layout location={location}>
        <Helmet>
          <title>{`${post.title} | ${config.siteTitle}`}</title>
        </Helmet>
        <SEO pageType="post" postPath={slug} postNode={post} />

        <SocialShares
          postPath={slug}
          title={post.title}
          description={post.description}
          pinterestMedia={post.cover.file.url}
        />

        {post.products ? (
          <section className="section is-paddingless-horizontal has-background-white-ter">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-12">
                  <h3 className="title is-size-6" style={{ marginLeft: 10 }}>
                    My Selection
                  </h3>
                  <ProductCarousel products={post.products} />
                </div>
              </div>
            </div>
          </section>
        ) : null}

        <article>
          <section className="section">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-3">
                  <div className="section is-paddingless-horizontal">
                    <h3 className="title is-size-4">Final Sales Alerts</h3>
                    <SaleBanner />
                  </div>
                </div>
                <div className="column is-7-fullhd is-8-widescreen is-9-tablet">
                  <div className="section is-paddingless-horizontal">
                    <Link
                      key={post.category.id}
                      className="is-uppercase is-size-7 has-text-danger has-text-weight-semibold has-letter-spacing"
                      to={`/category/${post.category.slug}`}
                    >
                      {post.category.title}
                    </Link>

                    <div className="is-uppercase is-size-7 has-text-grey has-text-weight-semibold has-letter-spacing">
                      LAST UPDATED:
                      <time dateTime={post.updatedAt}>
                        {" "}
                        {moment(post.updatedAt).format(config.dateFormat)}
                      </time>
                    </div>
                  </div>
                  <h1 className="title is-2 is-size-3-mobile">{post.title}</h1>
                  <figure>
                    <GatsbyImage
                      image={post.cover.gatsbyImageData}
                      alt={post.title}
                      title={post.title}
                    />
                  </figure>
                  <hr />
                  <div
                    className="content"
                    ref={el => {
                      this.markdownContainer = el;
                    }}
                    dangerouslySetInnerHTML={{
                      __html: post.body.childMarkdownRemark.html
                    }}
                  />
                  <br />
                  <PostTags tags={post.tags} />
                </div>
              </div>
            </div>
          </section>
        </article>

        {post.products ? (
          <section className="section is-paddingless-horizontal has-background-white-ter">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-12">
                  <h3 className="title is-size-6" style={{ marginLeft: 10 }}>
                    My Selection
                  </h3>
                  <ProductCarousel products={post.products} />
                </div>
              </div>
            </div>
          </section>
        ) : null}

        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-7-fullhd is-8-widescreen is-9-tablet">
                <Disqus post={post} />
              </div>
            </div>
          </div>
        </section>

        <section className="section has-background-white-ter">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-5-widescreen is-6-tablet">
                {previous ? (
                  <>
                    <div className="is-uppercase is-size-7 has-text-danger has-text-weight-semibold has-letter-spacing">
                      &laquo; Previous Post
                    </div>
                    <Link to={`/${previous.slug}`} className="title is-size-4">
                      {previous.title}
                    </Link>
                  </>
                ) : null}
              </div>
              <hr className="is-hidden-tablet" />
              <div className="column is-5-widescreen is-6-tablet has-text-right">
                {next ? (
                  <>
                    <div className="is-uppercase is-size-7 has-text-danger has-text-weight-semibold has-letter-spacing">
                      Next Post &raquo;
                    </div>
                    <Link to={`/${next.slug}`} className="title is-size-4">
                      {next.title}
                    </Link>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      description
      category {
        id
        title
        slug
      }
      tags {
        id
        title
        slug
      }
      cover {
        title
        gatsbyImageData(
          width: 780
          layout: CONSTRAINED
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
        file {
          url
        }
      }
      createdAt
      updatedAt
      isFeatured
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
      products {
        id
        description
        brand {
          title
        }
        slug
        price
        originalPrice
        currency {
          symbol
        }
        image {
          gatsbyImageData(
            width: 440
            layout: CONSTRAINED
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
`;
